
import { TicketTypes } from "@/store/modules/ticket/ticket.types";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Ticket = namespace("Ticket");

@Component({
  components: {
    IDatePicker: () => import("@/components/utils/IDatePicker.vue"),
    AddTicket: () => import("@/components/ticket/AddTicket.vue"),
    EditTicket: () => import("@/components/ticket/EditTicket.vue"),
    DeleteTicket: () => import("@/components/ticket/DeleteTicket.vue"),
    ViewTicket: () => import("@/components/ticket/ViewTicket.vue"),
    ListTickets: () => import("@/components/ticket/ListTickets.vue"),
    CloseTicket: () => import("@/components/ticket/CloseTicket.vue"),
  },
})
export default class TicketsView extends Vue {
  @Ticket.Mutation(TicketTypes.SET_ADD_TICKET_DIALOG)
  public setAddTicket!: (addTicket: boolean) => void;

  @Ticket.State("addTicketRef")
  public addTicketRef!: number;

  openAddTicketDialog(): void {
    this.setAddTicket(true);
  }

  public search = "";
}
